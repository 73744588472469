import React from 'react'
import { Box } from 'reflexbox'

import InformationBackground from 'src/assets/images/about-background.svg'
import Container from 'src/components/UI/Grid/Container'
import Row from 'src/components/UI/Grid/Row'
import Column from 'src/components/UI/Grid/Column'

import styles from './Information.module.scss'

const Information = () => {
  return (
    <Container className={styles.wrapper}>
      <Row
        flexDirection="column"
        alignItems="center"
        paddingTop={[30, null, null, 59]}
        paddingBottom={[40, null, null, 59]}
      >
        <Column width={[1, null, null, 10 / 12]} className={styles.content}>
          <Box marginBottom={[8]}>
            <h2 className={styles.title}>Ska vi byta?</h2>
            <p>
              Sommaren 2015 fick Anders frågan “ska vi byta?” på Instagram. En
              engelsman ville byta sin egentillverkade skål mot Anders
              handgjorda bestick. “Ska vi byta” är en bra fråga, så bra att den
              blev startskottet för Swapp – en tjänst som möjliggör byten.
            </p>
            <p>
              Sedan dess har vi jobbat med att utveckla Swapp. Målet är att
              bygga en tjänst som är enkel och rolig, som aldrig kommer att
              handla om pengar och som gör det möjligt för människor att byta
              saker med varandra.
            </p>
          </Box>
          <Box>
            <h2 className={styles.title}>Byt allt du inte använder längre.?</h2>
            <p>
              Swapp tar spjärn mot konsumtionshetsen, våra överfyllda garderober
              och förråd. Vi vill värna om det vi redan har och se värdet i det
              som vi i vanliga fall skulle slänga eller gömma undan. Och vi
              hoppas att du vill göra det också.
            </p>
            <p>
              Saker som du tröttnat på kan nämligen bli nya och roliga i
              händerna på någon annan. Eftersom livet på dina prylar förlängs
              gör du någonting bra – för dig själv, någon annan och vår planet.
              Bra va?
            </p>
          </Box>
        </Column>
        <img
          className={styles.image}
          src={InformationBackground}
          alt="React Logo"
        />
      </Row>
    </Container>
  )
}

export default Information
