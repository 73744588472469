import React from 'react'

import Video from 'src/components/Sections/About/Video/Video'
import Information from 'src/components/Sections/About/Information/Information'

import styles from './About.module.scss'

const About = () => {
  return (
    <section>
      <div id="about" className={styles.anchor} data-anchor />
      <Video />
      <Information />
    </section>
  )
}

export default About
