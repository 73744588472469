import * as React from 'react'
import Newsletter from 'src/components/Sections/Newsletter/Newsletter'

import Hero from 'src/components/Sections/Hero/Hero'
import About from 'src/components/Sections/About/About'

const Index = () => {
  return (
    <div>
      <Hero />
      <About />
      <Newsletter />
    </div>
  )
}

export default Index
