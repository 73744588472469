/* eslint-disable */
import React, { Component } from 'react'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import { Flex, Box } from 'reflexbox'

const url =
  'https://getswapp.us14.list-manage.com/subscribe/post?u=d347da244b2a93dadc27fb51a&amp;id=79b23ee0bd'

import styles from './MailchimpForm.module.scss'
class CustomForm extends Component {
  state = {
    status: '',
    message: '',
    firstName: '',
    lastName: '',
    email: '',
    validEmail: false,
    // acceptTerms: false,
  }

  validateEmail = (event: any) => {
    const email = event.target.value
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const isValid = re.test(String(email).toLowerCase())

    this.setState({ email: email, validEmail: isValid })
  }

  componentDidUpdate(nextProps) {
    if (nextProps.status !== this.props.status) {
      if (this.props.status) {
        this.setState({ status: this.props.status })
      }
    }
    if (nextProps.message !== this.props.message) {
      if (this.props.message) {
        this.setState({ message: this.props.message })
      }
    }
  }

  render() {
    const { onSubmitted } = this.props
    const {
      status,
      message,
      firstName,
      lastName,
      email,
      validEmail,
    } = this.state

    const submitForm = (event) => {
      if (firstName && lastName && email?.indexOf('@') > -1) {
        event?.preventDefault()
        return onSubmitted({
          EMAIL: email || '',
          FNAME: firstName || '',
          LNAME: lastName || '',
        })
      }
    }

    if (status === 'error') {
      setTimeout(() => {
        this.setState({
          status: '',
          message: '',
          firstName: '',
          lastName: '',
          email: '',
          validEmail: false,
        })
      }, 6000)
    }

    return (
      <React.Fragment>
        <Flex
          width={[1, null, null, 1 / 2]}
          as="form"
          className={styles.form}
          flexDirection="column"
        >
          <Flex
            className={styles.input}
            flexDirection="column"
            marginBottom={[4, null, null, 4]}
          >
            <input
              type="text"
              id="firstName"
              name="firstName"
              aria-label="Förnamn"
              placeholder="Förnamn"
              value={firstName}
              onChange={(e) =>
                this.setState({
                  firstName: e.target.value,
                })
              }
              className={!firstName ? styles.error : ''}
            />
            <label htmlFor="firstName" />
          </Flex>
          <Flex
            className={styles.input}
            flexDirection="column"
            marginBottom={[4, null, null, 4]}
          >
            <input
              type="text"
              id="lastName"
              name="lastName"
              aria-label="Efternamn"
              placeholder="Efternamn"
              value={lastName}
              onChange={(e) =>
                this.setState({
                  lastName: e.target.value,
                })
              }
              className={!lastName ? styles.error : ''}
            />
            <label htmlFor="lastName" />
          </Flex>
          <Flex
            className={styles.input}
            flexDirection="column"
            marginBottom={[4, null, null, 4]}
          >
            <input
              type="email"
              id="email"
              name="email"
              aria-label="E-post"
              placeholder="E-post"
              value={email}
              onChange={this.validateEmail}
              className={!validEmail ? styles.error : ''}
            />
            <label htmlFor="email" />
          </Flex>
          <Box>
            <button
              className={styles.submit}
              disabled={
                status === 'success' || !firstName || !lastName || !validEmail
              }
              onClick={submitForm}
            >
              {status === 'sending' && 'Skickar'}
              {status === 'error' && 'Något gick fel'}
              {status === 'success' && 'Registrerad'}
              {!status && 'Skicka'}
            </button>
          </Box>
        </Flex>
        <Box marginTop={[4, null, null, 4]} width={1}>
          {message && (
            <div
              className={[
                styles.text,
                status === 'error' ? styles.error : '',
              ].join(' ')}
              dangerouslySetInnerHTML={{
                __html: message,
              }}
            />
          )}
        </Box>
      </React.Fragment>
    )
  }
}

const MailchimpForm = () => (
  <MailchimpSubscribe
    url={url}
    render={({
      subscribe,
      status,
      message,
    }: {
      subscribe: any
      status: string
      message: string
    }) => (
      <CustomForm
        status={status}
        message={message}
        onSubmitted={(formData: any) => subscribe(formData)}
      />
    )}
  />
)

export default MailchimpForm
