import React from 'react'
import { Box } from 'reflexbox'

import PhonePreview from 'src/assets/images/swapp-screens.gif'
import Container from 'src/components/UI/Grid/Container'
import Row from 'src/components/UI/Grid/Row'
import Column from 'src/components/UI/Grid/Column'
import MailchimpForm from 'src/components/Sections/Newsletter/MailchimpForm/MailchimpForm'

import styles from './Newsletter.module.scss'

const Newsletter = () => (
  <Container paddingY={[20]} className={styles.wrapper}>
    <div id="signup" className={styles.anchor} data-anchor />
    <Row alignItems="center">
      <Column width={[1, null, null, 4 / 12]}>
        <img className={styles.image} src={PhonePreview} alt="Swapp Screens" />
      </Column>
      <Column
        marginLeft={[0, null, null, (2 / 12) * 100 + '%']}
        width={[1, null, null, 6 / 12]}
      >
        <Box marginBottom={[4, null, null, 4]}>
          <h2 className={styles.title}>
            Vill du vara med från början?
            <br /> Det är gratis. !!!
          </h2>
        </Box>
        <p className={styles.text}>
          Skriv upp dig på väntelistan och var först ut med att veta när vi
          släpper tjänsten. Vi kommer aldrig att lämna ut dina uppgifter till
          någon. Vi lovar.
        </p>
        <Box marginTop={[4, null, null, 4]} width={[1, null, null, 1]}>
          <MailchimpForm />
        </Box>
      </Column>
    </Row>
  </Container>
)
export default Newsletter
