import React from 'react'
import { Box } from 'reflexbox'

import HeroBackground from 'src/assets/images/hero-background.svg'
import Container from 'src/components/UI/Grid/Container'
import Row from 'src/components/UI/Grid/Row'
import Column from 'src/components/UI/Grid/Column'

import styles from './Hero.module.scss'

const Hero = () => {
  return (
    <Container as="section" className={styles.section} id="hero">
      <Row
        className={styles.wrapper}
        flexDirection="column"
        alignItems="center"
      >
        <Column
          className={styles.content}
          width={[1, null, 8 / 12, null, 10 / 12]}
        >
          <Box marginBottom={[4]}>
            <h1 className={styles.title}>Buy less. Get more.</h1>
          </Box>
          <p className={styles.text}>
            Swapp är en tjänst där du kan byta
            <br /> vad som helst, när som helst med vem som helst.
          </p>
        </Column>
      </Row>
      <img
        className={styles.image}
        src={HeroBackground}
        alt="Swapp Background"
      />
    </Container>
  )
}

export default Hero
