import React from 'react'

import image from 'src/assets/images/video.png'
import YouTubeVideo from 'src/components/YouTubeVideo/YouTubeVideo'
import Container from 'src/components/UI/Grid/Container'
import Row from 'src/components/UI/Grid/Row'
import Column from 'src/components/UI/Grid/Column'

import styles from './Video.module.scss'

const Video = () => {
  return (
    <Container className={styles.wrapper}>
      <Row flexDirection="column" alignItems="center">
        <Column width={[1, null, null, 10 / 12]}>
          <h2 className={styles.title}>Se vad det handlar om.</h2>
          <YouTubeVideo id="RZEwHY2-RyY" image={image} />
        </Column>
      </Row>
    </Container>
  )
}

export default Video
